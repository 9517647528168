<script>
    import { aboutListDetail } from "@/api/my";
    export default {
        components: {
            Header: () => import('components/base/Header'),

            'mt-header':app.Mint.Header,
            'mt-button':app.Mint.Button,
        },
        data() {
            return {
                list: [],
                title: "",
                content: "",
                header:{
                    mainTitle3:null
                }
            };
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                if (hisNum == 1) {
                    this.$router.push("/home");
                } else {
                    this.$router.go(-1);
                }
            },
            //下拉无限刷新页面的条数
            loadMore() {
                if (this.list.length < this.length) {
                    this.loading = true;
                    setTimeout(() => {
                        let last = this.list[this.list.length - 1];
                        for (let i = 1; i <= 1; i++) {
                            this.list.push(last + i);
                        }
                        this.loading = false;
                    }, 50);
                }
            },
            //列表细节
            getmesDetail(id) {
                aboutListDetail(id).then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.content = result.data.result && result.data.result.content;
                        this.title = result.data.result && result.data.result.title;
                        this.header.mainTitle3=this.title;
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            }
        },
        mounted() {
            this.getmesDetail(this.$route.query.id);
        }
    };
</script>
<template>
    <div class="about" id="about" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
            <div class="myposition"></div>
            <div class="main">
                <mt-header :title="title" style="height:0.88rem;font-size: 0.35rem;">
                    <mt-button icon="back" slot="left" @click="left_click()"></mt-button>
                </mt-header>
                <div class="content" v-html="content"></div>
            </div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header 
                :mainTitle="header.mainTitle3" 
                :backButton=false 
            />
            <div class="content" v-html="content"></div>
        </template>
    </div>
</template>
<style scoped>
.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.content {
    float: left;
    width: 7.25rem;
    height: 2.1rem;
    font-size: 0.3rem;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 0.45rem;
    margin: 0.5rem 0 0 0.2rem;
}
</style>
